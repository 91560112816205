import React from 'react';
import ComponentTitle from '@src/components/ComponentTitle';
import { TvGuideItem } from '@src/interfaces';
import TvTimelineItem from '../TvTimelineItem';
import styles from './styles.module.scss';

const TvTimeline = ({title, items}: {title?: string, items: TvGuideItem[]}) => (
  <>
    <ComponentTitle title={title} />
    <div className={styles.tvTimeline}>
      {items?.map((item: TvGuideItem, index) => {
        let heading;
        switch (index) {
          case 1: heading = 'Gleich danach'
            break;
          case 2: heading = '\u00A0'
            break;
          case 3: heading = '\u00A0'
            break;
          default: heading = item.now ? 'Jetzt' : '\u00A0';
        }
        return <TvTimelineItem key={index} heading={heading} index={index} {...item} />;
      })}
    </div>
  </>
);
export default TvTimeline;
